import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.handleClick && $options.handleClick(...args))),
    id: $props.name,
    class: "game-button border-2 m-2 p-2 rounded-lg hover:bg-sun hover:scale-105 transition-all"
  }, _toDisplayString($props.name), 9, _hoisted_1))
}