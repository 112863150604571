import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "flag pointer-events-none" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "name hidden pointer-events-none text-l text-center text-zinc-200" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: $props.code,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.handleClick && $options.handleClick(...args))),
    class: "country-component w-40 h-40 justify-around flex-col items-center border-2 rounded-lg transition-all border-white cursor-pointer hover:bg-sun hover:scale-105 hidden"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: require(`../assets/imgs/svg/${$props.code?.toLowerCase()}.svg`),
        alt: $props.name,
        class: "w-20"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString($props.name), 1)
  ], 8, _hoisted_1))
}